import Comunicator from 'comunicator/src/index'
// import Comunicator from '../../comunicator_wrapper/src/index'
import RealtimeInterface from 'comunicator/src/realtime-service/interface.js'
// import RealtimeInterface from '../../comunicator_wrapper/src/realtime-service/interface.js'
import http from '../utils/services/http'
import Vue from 'vue'
import env from '../env'

export const createComunicator = function (token = '', domain = '') {
  Vue.prototype.comunicatorClass = new Comunicator(process.env.VUE_APP_GATEWAY, RealtimeInterface, http)
  Vue.prototype.comunicator = this.comunicatorClass.comunicator.connect(env.websocket + `?token=${token}&client=${domain}`, {})
  //this.comunicator.subscribe('all')
  //this.$globalConfig && this.$globalConfig.clientId && this.comunicator.subscribe(this.$globalConfig.clientId);

  // bind events
}
