<script>
/* eslint-disable */
import DefaultMixin from '../mixin'
import BtnStatusLeilao from './context/BtnStatusLeilao'
import LeilaoInfo from './include/pages/LeilaoInfo'
import BtnImportacaoLeilao from './context/BtnImportacaoLeilao'
import BtnAcoesLeilao from './context/BtnAcoesLeilao'
import BtnLoteamentoLeilao from './context/BtnLoteamentoLeilao'
import ListaLotes from './include/ListaLotes'
import ComitentesParticipantes from './include/pages/ComitentesParticipantes'
import * as StatusLeilao from '../../../domain/leiloes/helpers/LeilaoStatus'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import MenuSuperior from "@/components/leiloes/components/menu/MenuSuperior"
import AlteracaoStatusLeilaoMixin from "components/leiloes/components/include/AlteracaoStatusLeilaoMixin"

export default {
  name: 'Leilao',
  inject: ['page'],
  provide: function () {
    return {
      leilaoPage: this
    }
  },
  mixins: [DefaultMixin, AlteracaoStatusLeilaoMixin],
  components: {
    MenuSuperior,
    ComitentesParticipantes,
    ListaLotes,
    BtnLoteamentoLeilao,
    BtnAcoesLeilao,
    BtnImportacaoLeilao,
    LeilaoInfo,
    BtnStatusLeilao,
    ERow,
    ECol
  },
  data() {
    return {
      leilao: this.page.leilao
    }
  },
  computed: {
    StatusLeilao() {
      return StatusLeilao
    }
  },
  mounted() {
    // console.log('--------')
    // console.log('--------')
    // console.log('Leilao: ', this,leilao)
    this.erplayout && this.erplayout.erpheader.setMenuModuleToggle(true)
    this.page.setActive('hom')
    if (this.page && this.page.leilao) {
      this.$options.meta.title = `Leilão ${this.page.leilao.codigo}`
    }
  },
  destroyed() {
  },
  methods: {
    loteamentoAutomatico(ordering) {
      this.$refs.lotes.loteamentoAutomatico(ordering)
    },
    updateLeilaoEvent() {
      this.page.load()
    },
    adicionarSublotes(lote) {
      this.$refs.btnLoteamento.criacaoRapidaBens(this.leilao, null, lote)
    },/*,
    teste ($event) {
      console.log(this.stats)
      this.stats.SLEventClient(this.stats.SL_EVENT_TYPES.CLICK, $event, 'leilao', 1, 'erp.localhost')
    }*/
  },
  meta: {
    title: 'Leilão',
    name: 'Leilão'
  }
}
</script>

<template>
  <div class="page-leilao-lotes page-leilao-container">
    <div class="row">
      <div class="col flex">
        <e-btn class="text-left leilao-status bg-purple text-white no-border no-select" style="cursor: default"
               align="left" label="Venda Direta" v-if="leilao.vendaDireta"/>
      </div>
      <div class="col text-right">
        <div>
          <btn-acoes-leilao
              :leilao="leilao"
              @alteraStatusInterno="updateStatusInterno"
              @prepararLeilao="updateStatusInterno(StatusLeilao.STATUS_INTERNO_PREPARADO)"
              @updateLeilao="updateLeilaoEvent"
          /> <!-- v-if="cracl(null, ['sl/leilao/update', 'sl/leilao/alterar-status-interno'])" -->
          <btn-loteamento-leilao
              ref="btnLoteamento"
              @loteamentoManual="() => $refs.lotes.loteamentoManual()"
              @lotearAutomatico="loteamentoAutomatico"
              @loteAdicionado="$refs.lotes.load()"
              @updateLotes="$refs.lotes.load()"
              class="m-l-xs m-r-xs"
              :leilao="leilao"/> <!-- v-if="cracl(null, ['sl/leilao/add-lotes'])" -->
          <btn-importacao-leilao
              :leilao="leilao"/> <!-- v-if="cracl(null, ['sl/leilao/update', 'sl/leilao/importacao'])" -->
        </div>
      </div>
    </div>
    <lista-lotes
        ref="lotes"
        @loaded="(lotes) => leilao.lotes = lotes"
        class="m-t"
        :leilao="leilao"
        @adicionarSublotes="adicionarSublotes"
    />

    <comitentes-participantes :leilao="leilao"/>
  </div>
</template>
